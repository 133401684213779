import React from 'react';
import styled from '@emotion/styled';

import Devider from './Devider';

const SectionDevider = () => {
    return (
        <Container>
            <Devider />
        </Container>
    );
};

export default SectionDevider;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 64px;
    margin-bottom: 30px;
`;
