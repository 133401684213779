import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { SuccessStoryItemModel } from '../../models';
import { FONT_GOTHAM_LIGHT } from '../../styles/fonts';
import { CompatibleImg } from '../Img';
import { FlexSpacer, RoundedButton, device } from '../commonStyled';
import { ROUTES } from '../../constants';

interface CoursesItemProps {
    item: SuccessStoryItemModel;
    className?: string;
}

const SuccessStoryItem: React.FC<CoursesItemProps> = ({
    item: { photo, jobtitle, short_feedback, name, slug },
    className,
}) => {
    return (
        <Container
            to={`/${ROUTES.istoriiUspeha}/${slug}`}
            className={className}
        >
            {photo?.localFile && (
                <CompatibleImg
                    image={photo.localFile}
                    imageStyle={imageStyle}
                />
            )}
            {name && <Title dangerouslySetInnerHTML={{ __html: name }} />}
            <JobTitle dangerouslySetInnerHTML={{ __html: jobtitle }} />
            <Description dangerouslySetInnerHTML={{ __html: short_feedback }} />
            <FlexSpacer />
            <Button as="div">Читать историю</Button>
        </Container>
    );
};

const JobTitle = styled.div`
    color: #000000;
    font-family: ${FONT_GOTHAM_LIGHT};
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    min-height: 48px;
`;

const imageStyle: CSSProperties = {
    position: 'absolute',
    top: '-57px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '149px',
    height: '149px',
    borderRadius: '50%',
    backgroundColor: 'white',
};

const Title = styled.div`
    color: #000000;
    font-family: ${FONT_GOTHAM_LIGHT};
    font-size: 24px;
    margin: 104px 0 0px 0;
    text-align: center;
`;

const Description = styled.div`
    color: #000000;
    line-height: 24px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 12px;

    p {
        padding: 0;
    }
`;

const Button = styled(RoundedButton)`
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
`;

const Container = styled(Link)`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    text-decoration: none;
    box-shadow: 9px 7px 65px 0 #d8cece;
    margin: 57px 57px 73px 73px;
    background-color: #ffffff;
    min-height: 425px;
    border-radius: 22px;

    @media ${device.mobileMedium} {
        margin: 57px 24px 73px 24px;
    }

    padding: 12px;

    &:hover {
        color: #000;
    }
`;

export default SuccessStoryItem;
