import React from 'react';
import styled from '@emotion/styled';

import iconUpdate from '../../images/update-icon.png';
import SectionTitle from '../SectionTitle';
import { useStickyPosts } from '../../queries/usePosts';
import { device, RoundedButtonInternalLink } from '../commonStyled';
import SectionDevider from '../SectionDevider';

import ArticleMain from './ArticleMain';
import Article from './Article';

interface ArticlesSectionProps {
    title: string;
}

const ArticlesSection: React.FC<ArticlesSectionProps> = ({ title }) => {
    const items = useStickyPosts();

    if (!items.length) {
        return null;
    }

    return (
        <>
            <SectionDevider />
            <Container>
                <SectionTitle title={title} />
                <ArticlesContainer>
                    <ArticleMain item={items[0]} />
                    {items.length > 1 && (
                        <BottomArticles>
                            {items.slice(1).map((item) => (
                                <Article key={item.title} item={item} />
                            ))}
                        </BottomArticles>
                    )}
                </ArticlesContainer>
                <Button to="/articles" rel="nofollow">
                    <ButtonIcon iconProp={iconUpdate} />
                    Больше статей
                </Button>
            </Container>
        </>
    );
};

const ButtonIcon = styled('div')<{ iconProp: string }>`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 34px;
    height: 18px;
    width: 18px;
    background-image: url(${({ iconProp }) => iconProp});
`;

const Button = styled(RoundedButtonInternalLink)`
    position: relative;
    padding-left: 66px;
    font-size: 16px;
    line-height: 24px;
`;

const ArticlesContainer = styled.div`
    max-width: 1100px;
    margin-bottom: 60px;
`;

const BottomArticles = styled.div`
    display: flex;
    margin-top: 52px;

    @media ${device.tabletMedium} {
        flex-direction: column;
        margin-top: 24px;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${device.laptopSmall} {
        padding: 0 12px;
    }
`;

export default ArticlesSection;
