import React, { useState } from 'react';
import styled from '@emotion/styled';
import Axios from 'axios';

import { FONT_GOTHAM_MEDIUM } from '../styles/fonts';
import { validateEmail } from '../utils/utils';
import { WP_FORM_API_CONSULT } from '../constants';

import Checkbox from './Checkbox';
import { RoundedButtonStyles, device, ContentMain } from './commonStyled';
import LoadingBlocker from './LoadingBlocker';
import { Input } from './Input';
import { Textarea } from './TextArea';

interface FormProps {
    title?: string;
    subtitle?: string;
    apiUrl?: string;
    placeholders?: {
        text?: string;
        name?: string;
        email?: string;
        phone?: string;
    };
    buttonText: string;
}

const Form: React.FC<FormProps> = ({
    title,
    subtitle,
    apiUrl,
    placeholders,
    buttonText,
}) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [text, setText] = useState('');
    const [isAgree, setIsAgree] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isErrorSending, setIsErrorSending] = useState(false);

    const onChange = (setValue) => (e) => setValue(e.target.value);

    const resetForm = () => {
        setName('');
        setEmail('');
        setPhone('');
        setText('');
        setIsAgree(false);
        setIsError(false);
        setIsLoading(false);
        setIsSuccess(false);
        setIsErrorSending(false);
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        if (name && email && validateEmail(email) && text && isAgree) {
            const bodyFormData = new FormData();
            bodyFormData.set('your-name', name);
            bodyFormData.set('your-email', email);
            bodyFormData.set('your-phone', phone);
            bodyFormData.set('your-message', text);

            setIsLoading(true);
            try {
                const res = await Axios.post(
                    apiUrl || WP_FORM_API_CONSULT,
                    bodyFormData,
                    {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    }
                );

                if (res.data.status === 'mail_sent') {
                    setIsSuccess(true);
                    setTimeout(resetForm, 3000);
                }
            } catch (e) {
                setIsErrorSending(true);
            } finally {
                setIsLoading(false);
            }
        } else {
            setIsError(true);
        }
    };

    return (
        <ContentMain>
            <Container>
                {title && <FormTitle>{title}</FormTitle>}
                {subtitle && <FormSubTitle>{subtitle}</FormSubTitle>}
                <FormContainer onSubmit={onSubmit}>
                    <Input
                        placeholder={placeholders?.name || 'Ваше имя *'}
                        value={name}
                        onChange={onChange(setName)}
                        type="text"
                        id="name"
                        isError={isError && !name}
                    />
                    <Input
                        placeholder={placeholders?.email || 'Ваш e-mail *'}
                        value={email}
                        onChange={onChange(setEmail)}
                        type="email"
                        isError={isError && (!email || !validateEmail(email))}
                    />
                    <Input
                        placeholder={
                            placeholders?.phone || 'Ваш номер телефона'
                        }
                        onChange={onChange(setPhone)}
                        value={phone}
                        type="phone"
                        isError={isError && !phone}
                    />
                    <Textarea
                        placeholder={
                            placeholders?.text || 'Опишите ваш запрос *'
                        }
                        onChange={onChange(setText)}
                        value={text}
                        isError={isError && !text}
                        minRows={4}
                    />
                    <AgreeBoxContainer>
                        <CheckboxStyled
                            isChecked={isAgree}
                            onChange={(e) => setIsAgree(e.target.checked)}
                            isError={isError && !isAgree}
                        />
                        <AgreeBox>
                            Соглашаюсь на обработку персональных данных в
                            соответствии с Политикой конфиденциальности
                        </AgreeBox>
                    </AgreeBoxContainer>
                    <SubmitButton type="submit">{buttonText}</SubmitButton>
                    <LoadingBlocker
                        isLoading={isLoading}
                        isError={isErrorSending}
                        isSuccess={isSuccess}
                        errorMessage="Возникла ошибка, попробуйте отправить форму ещё раз"
                        successMessage="Спасибо, Ваша заявка принята."
                    />
                </FormContainer>
            </Container>
        </ContentMain>
    );
};

const CheckboxStyled = styled(Checkbox)`
    position: absolute;
    top: 0;
    left: -25px;
`;

const AgreeBoxContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;

    @media ${device.mobileMedium} {
        margin-left: 25px;
    }
`;

const AgreeBox = styled.div`
    color: #000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    max-width: 473px;

    @media ${device.mobileMedium} {
        text-align: left;
        max-width: none;
    }
`;

const FormSubTitle = styled.div`
    font-size: 18px;
    color: #000000;
    text-align: center;
    margin-bottom: 37px;
`;

const FormTitle = styled.div`
    color: #000000;
    font-size: 24px;
    font-family: ${FONT_GOTHAM_MEDIUM};
    text-align: center;
`;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const SubmitButton = styled.button`
    ${RoundedButtonStyles}
    width: auto;
    background-color: #a60424;
    color: #ffffff;
    margin-top: 20px;
`;

const FormContainer = styled.form`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 675px;
`;

export default Form;
