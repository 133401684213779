import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';

import { FONT_GOTHAM_MEDIUM, FONT_GOTHAM_LIGHT } from '../styles/fonts';
import { SharpImage } from '../models';

import { CompatibleImg } from './Img';
import { device } from './commonStyled';

interface CourseCardProps {
    image: SharpImage;
    subTitle?: string;
    title?: string;
    className?: string;
    cardType?: string;
    fontSize?: number;
}

const CourseCard: React.FC<CourseCardProps> = ({
    image,
    subTitle,
    title,
    className,
    fontSize,
    cardType,
}) => {
    return (
        <ContainerCard className={className}>
            {cardType && <CardType fontSize={fontSize}>{cardType}</CardType>}
            <TextContainer hasCardType={Boolean(cardType)}>
                {title && (
                    <Title
                        fontSize={fontSize}
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                )}
                {subTitle && (
                    <Subtitle
                        fontSize={fontSize}
                        dangerouslySetInnerHTML={{ __html: subTitle }}
                    />
                )}
            </TextContainer>
            {image?.localFile && (
                <CompatibleImg
                    image={image?.localFile}
                    imageStyle={imageStyle}
                />
            )}
        </ContainerCard>
    );
};

const CardType = styled('div')<{ fontSize?: number }>`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 28px;
    line-height: 32px;
    text-transform: lowercase;
    font-size: ${({ fontSize }) => fontSize || 33}px;
    font-family: ${FONT_GOTHAM_MEDIUM};
    color: #fff;
    background-color: #a60424;
    border-radius: 0 0 33px 33px;
    z-index: 1;
`;

const imageStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
};
const TextContainer = styled('div')<{ hasCardType: boolean }>`
    position: absolute;
    top: ${({ hasCardType }) => (hasCardType ? 54 : 36)}px;
    left: 24px;
`;

const Title = styled('div')<{ fontSize?: number }>`
    position: relative;
    z-index: 1;
    font-family: ${FONT_GOTHAM_MEDIUM};
    font-weight: bold;
    font-size: ${({ fontSize }) => fontSize || 33}px;
    margin-bottom: 5px;

    @media ${device.laptopSmall} {
        font-size: 24px;
    }
`;

const Subtitle = styled('div')<{ fontSize?: number }>`
    position: relative;
    z-index: 1;
    font-family: ${FONT_GOTHAM_LIGHT};
    font-size: ${({ fontSize }) =>
        fontSize ? Math.round(fontSize * 0.9) : 25}px;
    margin-bottom: 5px;

    @media ${device.laptopSmall} {
        font-size: 18px;
    }
`;

const ContainerCard = styled('div')`
    position: relative;
    padding: 30px 24px;
    background-color: #f7f0ed;
    padding-top: 71%;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 1px 2px 0 rgba(25, 27, 26, 0.1),
        0 1px 8px 0 rgba(25, 27, 26, 0.08);
`;
export default CourseCard;
