import { useStaticQuery, graphql } from 'gatsby';
import { compareAsc, isPast } from 'date-fns';

import { CalendarEventsModel } from '../models';
import { formatWpDate, parseWpDate } from '../utils';

export const useCalendarEvents = (): CalendarEventsModel[] => {
    const { allWpCourse, allWpFreeMasterclass } = useStaticQuery(
        graphql`
            {
                allWpCourse(
                    sort: { fields: courses_acf___startDate, order: ASC }
                ) {
                    nodes {
                        title
                        acf: courses_acf {
                            start_date: startDate
                            start_date_raw: startDate
                            url
                        }
                    }
                }
                allWpFreeMasterclass(
                    sort: { fields: free_masterclasses_afc___date }
                ) {
                    nodes {
                        acf: free_masterclasses_afc {
                            start_date: date(
                                formatString: "YYYY-MM-DD"
                                locale: "RU"
                            )
                            start_date_raw: date(
                                formatString: "YYYY-MM-DD"
                                locale: "RU"
                            )
                            link
                            title
                            type
                        }
                    }
                }
            }
        `
    );

    const courses = allWpCourse?.nodes;
    const masterClasses = allWpFreeMasterclass?.nodes;

    return [
        ...(courses?.map(({ title, acf }) => ({
            title,
            event_type: 'Курс',
            start_date: formatWpDate(acf.start_date, 'dd MMMM'),
            link: acf.url,
            start_date_raw: parseWpDate(acf.start_date_raw),
        })) || []),
        ...(masterClasses?.map(
            ({ acf: { start_date, link, title, type, start_date_raw } }) => ({
                title,
                start_date_raw: parseWpDate(start_date_raw),
                start_date: formatWpDate(start_date, 'dd MMMM'),
                link,
                event_type: type,
            })
        ) || []),
    ]
        .filter((x) => !isPast(x.start_date_raw))
        .sort((a, b) => compareAsc(a.start_date_raw, b.start_date_raw));
};
