import React from 'react';
import styled from '@emotion/styled';

import { ServiceSlides } from '../../models';
import { FONT_GOTHAM_LIGHT } from '../../styles/fonts';
import line from '../../images/line.png';
import lineWebp from '../../images/line.webp';
import { DEFAULT_ALT } from '../../constants';
import { useWebpImage } from '../../hooks/useWebpImage';

import ServiceCardSlider from './ServiceCardSlider';

interface ServiceCardProps {
    slides: ServiceSlides[];
    title: string;
    sliderId: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
    slides,
    title,
    sliderId,
}) => {
    const lineSrc = useWebpImage(lineWebp, line);

    return (
        <ServiceContainer>
            <SlidesContainer>
                <ServiceCardSlider items={slides} sliderId={sliderId} />
            </SlidesContainer>
            <Line src={lineSrc} alt={DEFAULT_ALT} />
            <ServiceTitle>{title}</ServiceTitle>
        </ServiceContainer>
    );
};

const ServiceTitle = styled.div`
    color: #000000;
    text-align: center;
    font-size: 30px;
    line-height: 26px;
    font-family: ${FONT_GOTHAM_LIGHT};
`;

const Line = styled.img`
    width: 100%;
    height: 4px;
    margin-bottom: 20px;
`;

const SlidesContainer = styled.div`
    height: 387px;
    overflow: hidden;
    width: 324px;
    margin-bottom: 28px;
`;

const ServiceContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: 100%;
    text-decoration: none;
    width: 324px;
    margin-bottom: 36px;

    &:hover {
        color: #000;
    }
`;

export default ServiceCard;
