import { useStaticQuery, graphql } from 'gatsby';

import { ServiceModel, ServiceCategories, ServiceItems } from '../models';

export const useServices = (): ServiceModel[] => {
    const { allWpServices, allWpServicesType } = useStaticQuery(
        graphql`
            {
                allWpService {
                    nodes {
                        id
                        title
                        services_acf {
                            services {
                                link
                                image {
                                    localFile {
                                        childImageSharp {
                                            gatsbyImageData(width: 600)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                allWpServicesType: allWpService(
                    sort: { order: ASC, fields: slug }
                ) {
                    nodes {
                        slug
                        title
                        id
                    }
                }
            }
        `
    );

    const serviceCategories: ServiceCategories[] = allWpServicesType?.nodes;
    const services: ServiceItems[] = allWpServices?.nodes;

    const servicesWithCategories: ServiceModel[] = serviceCategories?.reduce(
        (acc, item) => [
            ...acc,
            {
                category: item.title,
                items: services
                    ?.filter((x) => x.services_type.includes(item.id))
                    .map((x) => ({ ...x.acf.services, title: x.title })),
            },
        ],
        []
    );

    return servicesWithCategories.filter((x) => x.items?.length);
};
