import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import Glide from '@glidejs/glide';
import { Link } from 'gatsby';

import { CompatibleImg } from '../Img';
import { ServiceSlides } from '../../models';
import { GlideBulletButton } from '../commonStyled';

interface ServiceCardSliderProps {
    items: ServiceSlides[];
    sliderId: string;
}

const ServiceCardSlider: React.FC<ServiceCardSliderProps> = ({
    items,
    sliderId,
}) => {
    if (!items?.length) {
        return null;
    }

    useEffect(() => {
        const glide = new Glide(`#${sliderId}`, {
            perView: 1,
            bound: true,
        }).mount();
    }, []);

    return (
        <Container>
            <SliderContainer>
                <div className="glide" id={sliderId}>
                    <div className="glide__track" data-glide-el="track">
                        <div className="glide__slides">
                            {items?.map((item, index) => (
                                <div className="glide__slide" key={index}>
                                    <LinkStyled to="">
                                        <CompatibleImgStyled
                                            image={item.image?.localFile}
                                        />
                                    </LinkStyled>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div
                        className="glide__bullets glide__bullets-always-visible"
                        data-glide-el="controls[nav]"
                    >
                        {items?.length > 1 &&
                            items?.map((item, index) => (
                                <GlideBulletStyled
                                    className="glide__bullet"
                                    data-glide-dir={`=${index}`}
                                />
                            ))}
                    </div>
                </div>
            </SliderContainer>
        </Container>
    );
};

const GlideBulletStyled = styled(GlideBulletButton)`
    height: 14px;
    width: 14px;
    background-color: #fff;
`;

const CompatibleImgStyled = styled(CompatibleImg)`
    height: 100%;
`;

const LinkStyled = styled(Link)`
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 33px;
    overflow: hidden;
`;

const SliderContainer = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;

    .glide {
        height: 100%;
        display: flex;
    }
    .glide__slide {
        height: 100%;
    }

    .glide__slides {
        height: 100%;
    }
`;

export default ServiceCardSlider;
