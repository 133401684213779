import { useStaticQuery, graphql } from 'gatsby';

import { PostStickyModel } from '../models';

export const useStickyPosts = (): PostStickyModel[] => {
    const { allWpPost } = useStaticQuery(
        graphql`
            {
                allWpPost(
                    filter: {
                        isSticky: { eq: true }
                        status: { eq: "publish" }
                    }
                ) {
                    nodes {
                        slug
                        date(locale: "ru_Ru", formatString: "DD MMMM")
                        title
                        featured_media: featuredImage {
                            node {
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(width: 800)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    return allWpPost?.nodes || [];
};
