import React from 'react';

import SectionWithSliderSticked from '../common/SectionWithSliderSticked/SectionWithSliderSticked';
import { CalendarEventsModel } from '../../models';
import { useCalendarEvents } from '../../queries/useCalendarEvents';

import CalendarEventsSliderItem from './CalendarEventsSliderItem';

const CalendarEventsSliderSection: React.FC<{ title: string }> = ({
    title,
}) => {
    const items = useCalendarEvents();

    return (
        <SectionWithSliderSticked<CalendarEventsModel>
            items={items}
            title={title}
            sliderElement={CalendarEventsSliderItem}
            sliderId="calendar_events"
            height={435}
            sliderOptions={{
                breakpoints: {
                    1800: {
                        perView: 2,
                    },
                },
            }}
        />
    );
};

export default CalendarEventsSliderSection;
