import React, { useState, useEffect, useRef } from 'react';
import Glide from '@glidejs/glide';
import styled from '@emotion/styled';

import line from '../../../images/line.png';
import lineWebp from '../../../images/line.webp';
import arrowWhite from '../../../images/arrow-white.png';
import arrowBlack from '../../../images/arrow-black.png';
import Devider from '../../Devider';
import { Spacer, device } from '../../commonStyled';
import SectionTitle from '../../SectionTitle';
import SectionDevider from '../../SectionDevider';
import { DEFAULT_ALT } from '../../../constants';
import { useWebpImage } from '../../../hooks/useWebpImage';

import SectionWithSliderStickedSlider from './SectionWithSliderStickedSlider';

interface SectionWithSliderStickedProps<TSliderItem> {
    title: string;
    sliderId: string;
    items: TSliderItem[];
    sliderElement: React.FC<{ item: TSliderItem }>;
    height?: number;
    sliderOptions?: {
        perView?: number;
        gap?: number;
        bound?: boolean;
        breakpoints?: any;
    };
}

function SectionWithSliderSticked<TSliderItem>({
    title,
    sliderElement,
    items,
    sliderId,
    height,
    sliderOptions = {},
}: SectionWithSliderStickedProps<TSliderItem>) {
    const [isShadowVisible, setIsShadowVisible] = useState(true);
    const glide = useRef(null);
    const isSliderDisabled = items.length <= 2;
    const { breakpoints, ...options } = sliderOptions;
    const lineSrc = useWebpImage(lineWebp, line);

    useEffect(() => {
        glide.current = new Glide(`#${sliderId}`, {
            perView: 3,
            gap: -80,
            bound: false,
            breakpoints: {
                1600: {
                    perView: 2,
                },
                1024: {
                    perView: 2,
                },
                700: {
                    focusAt: 'center',
                    perView: 1,
                    gap: 50,
                },
                ...(breakpoints || {}),
            },
            ...options,
        }).mount();

        glide.current.on('run', (e) => {
            if (glide.current.index === items.length - 1) {
                setIsShadowVisible(false);
            } else {
                setIsShadowVisible(true);
            }
        });

        if (isSliderDisabled) {
            glide.current.disable();
        }
    }, []);

    return (
        <>
            <MobileHeader>
                <SectionDevider />
                <SectionTitle title={title} />
            </MobileHeader>
            <Container heightProp={height}>
                <Line src={lineSrc} alt={DEFAULT_ALT} />
                <Spacer />
                <LeftPanel>
                    <Devider />
                    <SectionTitleStyled title={title} fontSize={36} />
                    <Controls>
                        <SlideButton
                            left
                            data-glide-dir="<"
                            onClick={() => glide.current.go('<')}
                        />
                        <SlideButton
                            right
                            data-glide-dir=">"
                            onClick={() => glide.current.go('>')}
                        />
                    </Controls>
                </LeftPanel>
                <SliderContainer>
                    <SliderWrapper isShadowVisible={isShadowVisible}>
                        <SectionWithSliderStickedSlider<TSliderItem>
                            items={items}
                            sliderElement={sliderElement}
                            sliderId={sliderId}
                            isSliderDisabled={isSliderDisabled}
                        />
                    </SliderWrapper>
                </SliderContainer>
            </Container>
        </>
    );
}

const Controls = styled.div`
    display: flex;
    width: 180px;
    justify-content: space-between;
`;

const SlideButton = styled('button')<{ right?: boolean; left?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    background: none;
    height: 54px;
    width: 81px;
    border: none;
    background-color: #ffffff;
    border-radius: 33px;

    transform: ${({ left }) => (left ? 'rotateY(180deg)' : '')};

    &:before {
        content: '';
        height: 14px;
        width: 20px;
        background-image: url(${arrowBlack});
        background-size: cover;
    }

    &:hover {
        border: 1px solid #a60424;
        background-color: #a60424;
        cursor: pointer;

        &:before {
            background-image: url(${arrowWhite});
        }
    }
`;

const SectionTitleStyled = styled(SectionTitle)`
    margin-top: 36px;
    margin-bottom: 48px;
`;

const SliderContainer = styled.div`
    position: relative;
    flex-grow: 1;
`;

const SliderWrapper = styled('div')<{ isShadowVisible: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:after {
        content: '';

        z-index: 1;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 40px;

        background: linear-gradient(
            270deg,
            rgba(247, 240, 237, 0) 0%,
            rgba(247, 240, 237, 0.76) 76.08%,
            rgba(247, 240, 237, 0.89) 88.6%,
            #f7f0ed 100%
        );
    }

    &:before {
        content: '';

        z-index: 1;
        position: absolute;
        bottom: 0;
        right: ${({ isShadowVisible }) => (isShadowVisible ? '0' : '-300')}px;
        height: 100%;
        width: 300px;

        pointer-events: none;
        transition: all 0.2s ease-in-out;

        background: linear-gradient(
            90deg,
            rgba(247, 240, 237, 0) 0%,
            rgba(247, 240, 237, 0.76) 76.08%,
            rgba(247, 240, 237, 0.89) 88.6%,
            #f7f0ed 100%
        );

        @media ${device.laptopSmall} {
            display: none;
        }
    }
`;

const LeftPanel = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 300px;
    flex-shrink: 0;
    margin-right: 34px;

    @media ${device.laptopMedium} {
        display: none;
    }
`;

const MobileHeader = styled.div`
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    margin-top: 60px;

    @media ${device.laptopMedium} {
        display: flex;
    }
`;

const Container = styled('div')<{ heightProp?: number }>`
    position: relative;
    display: flex;
    background-color: #f7f0ed;

    height: ${({ heightProp }) => heightProp || 665}px;
    width: 100%;
    overflow: hidden;
`;

const Line = styled.img`
    position: absolute;
    top: 0;
    left: 17px;
    transform: rotate(90deg);
    transform-origin: top left;
    width: 665px;
    height: 17px;
    object-fit: none;

    @media ${device.laptopMedium} {
        display: none;
    }
`;

export default SectionWithSliderSticked;
