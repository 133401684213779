import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import FontFaceObserver from 'fontfaceobserver';

import { FONT_GOTHAM_MEDIUM, FONT_GOTHAM_LIGHT } from '../../../styles/fonts';
import lineWebp from '../../../images/line.webp';
import line from '../../../images/line.png';
import {
    FlexSpacer,
    HighlightedText,
    device,
    RichTextView,
    ActionButton,
} from '../../commonStyled';
import { getCoords } from '../../../utils/utils';
import { useWebpImage } from '../../../hooks/useWebpImage';

interface ActionSectionProps {
    title: string;
    highlightedText: string;
    description: string;
    buttonText: string;
    linkButton: string;
    imagePart: JSX.Element;
    height?: number;
    contentWidth?: number;
    whiteStyle?: boolean;
}

const ActionSection: React.FC<ActionSectionProps> = ({
    buttonText,
    description,
    highlightedText,
    imagePart,
    title,
    linkButton,
    height,
    contentWidth = 581,
    whiteStyle = false,
}) => {
    const [lineTop, setLineTop] = useState(180);
    const titleRef = useRef(null);
    const containerRef = useRef(null);
    const lineSrc = useWebpImage(lineWebp, line);

    useEffect(() => {
        const updateLineTop = () => {
            if (titleRef.current && containerRef.current) {
                setLineTop(
                    getCoords(titleRef.current).top -
                        getCoords(containerRef.current).top
                );
            }
        };

        updateLineTop();

        window?.addEventListener('resize', updateLineTop, { passive: true });

        const font = new FontFaceObserver(FONT_GOTHAM_MEDIUM);

        font.load().then(() => {
            updateLineTop();
        });

        return () => {
            window?.removeEventListener('resize', updateLineTop);
        };
    }, [titleRef.current]);

    return (
        <Container
            heightProp={height}
            ref={containerRef}
            whiteStyle={whiteStyle}
        >
            <InfoContainer widthProp={contentWidth}>
                <Title>
                    <FlexSpacer />
                    {title}
                </Title>
                <HighlightedText marginBottom={42} ref={titleRef}>
                    {highlightedText}
                </HighlightedText>
                <RichTextViewStyled>
                    <Description
                        dangerouslySetInnerHTML={{
                            __html: description,
                        }}
                    />
                </RichTextViewStyled>
                {linkButton && (
                    <ActionButtonStyled
                        rel="nofollow"
                        target="_blank"
                        href={linkButton}
                    >
                        {buttonText}
                    </ActionButtonStyled>
                )}
            </InfoContainer>
            {imagePart}
            <Line bgImg={lineSrc} top={lineTop} />
        </Container>
    );
};

const Container = styled('div')<{ heightProp?: number; whiteStyle: boolean }>`
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 98px;
    margin-bottom: 50px;
    height: ${({ heightProp }) => (heightProp ? `${heightProp}px` : 'auto')};
    width: 100%;
    background-color: ${({ whiteStyle }) => (whiteStyle ? '#fff' : '#f7f0ed')};

    @media ${device.laptopSmall} {
        height: auto;
    }
`;

const ActionButtonStyled = styled(ActionButton)`
    margin-bottom: 50px;
    align-self: flex-start;
`;

const RichTextViewStyled = styled(RichTextView)`
    li:before {
        content: '\\2022';
        color: #a60424;
    }
`;

const Title = styled('div')`
    display: flex;
    flex-direction: column;
    color: #000000;
    font-size: 38px;
    font-family: ${FONT_GOTHAM_MEDIUM};
    margin: 0;
    margin-bottom: 28px;
    margin-top: 36px;

    @media ${device.laptopSmall} {
        font-size: 30px;
    }

    @media ${device.mobileMedium} {
        font-size: 27px;
    }
`;

const InfoContainer = styled('div')<{ widthProp: number }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 2;
    margin-top: 0;
    ${({ widthProp }) => widthProp && `max-width: ${widthProp}px;`}

    @media ${device.laptopSmall} {
        margin: 0;
        padding: 0 12px;
        align-items: center;
        text-align: center;
    }
`;

const Line = styled('div')<{ bgImg: string; top: number }>`
    position: absolute;
    ${({ top }) => top && `top: ${top}px;`}
    left: 0;
    width: 100%;
    height: 52px;
    background-image: url(${({ bgImg }) => bgImg});
    background-repeat: no-repeat;
    background-size: cover;
`;

const Description = styled.div`
    font-family: ${FONT_GOTHAM_LIGHT};
    color: #000000;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 36px;

    @media ${device.tabletMedium} {
        font-size: 16px;
        margin-bottom: 32px;
    }

    @media ${device.tabletMedium} {
        font-size: 14px;
    }
`;

export default ActionSection;
