import React from 'react';
import styled from '@emotion/styled';

import SectionWithSliderCentered from '../common/SectionWithSliderCentered/SectionWithSliderCentered';
import { MarketModel } from '../../models';
import { useMarket } from '../../queries/useMarket';
import SectionDevider from '../SectionDevider';
import SectionTitle from '../SectionTitle';

import ProductItem from './ProductItem';

interface ProductsSectionProps {
    title: string;
}

const ProductsSection: React.FC<ProductsSectionProps> = ({ title }) => {
    const items = useMarket();

    return (
        <>
            <SectionDevider />
            <SectionTitle title={title} />
            <Container>
                <SectionWithSliderCentered<MarketModel>
                    items={items}
                    sliderElement={ProductItem}
                    sliderId="section_market"
                    withShadows
                />
            </Container>
        </>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    margin-top: -50px;
`;

export default ProductsSection;
