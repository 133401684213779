import React from 'react';
import styled from '@emotion/styled';

import { useCourses } from '../../queries/useCourses';
import SectionWithSliderCentered from '../common/SectionWithSliderCentered/SectionWithSliderCentered';
import { CourseModel } from '../../models';
import SectionDevider from '../SectionDevider';
import SectionTitle from '../SectionTitle';

import CoursesItem from './CoursesItem';

interface CoursesProps {
    title: string;
}

const CoursesSection: React.FC<CoursesProps> = ({ title }) => {
    const items = useCourses();

    return (
        <>
            <SectionDevider />
            <SectionTitle title={title} />
            <Container>
                <SectionWithSliderCentered<CourseModel>
                    items={items}
                    sliderElement={CoursesItem}
                    sliderId="section_courses"
                />
            </Container>
        </>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
`;

export default CoursesSection;
