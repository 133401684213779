import { graphql, useStaticQuery } from 'gatsby';

import { MarketModel } from '../models';

export const useMarket = (): MarketModel[] => {
    const { allWpMarket } = useStaticQuery(
        graphql`
            {
                allWpMarket {
                    nodes {
                        market_acf {
                            link
                            short_description: shortDescription
                            button_text: buttonText
                            price
                            image {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(width: 350)
                                    }
                                }
                            }
                        }
                        title
                    }
                }
            }
        `
    );

    const nodes = allWpMarket?.nodes;

    return (
        nodes?.map((item) => ({ title: item.title, ...item?.market_acf })) || []
    );
};
