import React from 'react';
import styled from '@emotion/styled';

import { CompatibleImg } from '../Img';
import { useMainPageData } from '../../queries/useMainPageData';
import { device } from '../commonStyled';
import ActionSection from '../common/ActionSection/ActionSection';
import { CONTENT_WIDTH } from '../../constants';

const DESCRIPTION_WIDTH = 581;

interface ActionSectionBottomProps {
    whiteStyle?: boolean;
    infoText?: string;
    buttonText?: string;
    highlightedText?: string;
    title?: string;
    height?: number;
}

const ActionSectionBottom: React.FC<ActionSectionBottomProps> = ({
    whiteStyle,
    infoText,
    buttonText,
    title,
    highlightedText,
    height = 674,
}) => {
    const {
        action_block: {
            highlightedText: highlightedTextDeafult,
            image,
            infoText: infoTextDefault,
            buttonText: buttonTextDefault,
            buttonLinkBttomBlock,
            title: titleDeafault,
        },
    } = useMainPageData();

    return (
        <ActionSection
            buttonText={buttonText || buttonTextDefault}
            description={infoText || infoTextDefault}
            title={title || titleDeafault}
            highlightedText={highlightedText || highlightedTextDeafult}
            height={height}
            contentWidth={DESCRIPTION_WIDTH}
            imagePart={
                <CompatibleWrap>
                    <CompatibleContainer>
                        {image && <CompatibleImg image={image?.localFile} />}
                    </CompatibleContainer>
                </CompatibleWrap>
            }
            linkButton={buttonLinkBttomBlock}
            whiteStyle={whiteStyle}
        />
    );
};

const CompatibleWrap = styled.div`
    width: ${CONTENT_WIDTH - DESCRIPTION_WIDTH}px;
    position: relative;

    @media ${device.laptopMedium} {
        display: none;
    }
`;

const CompatibleContainer = styled.div`
    position: absolute;
    top: -100px;
    left: 0;
    right: -267px;
    bottom: 0;
    height: auto;
    flex-shrink: 0;
    z-index: 1;
    align-self: flex-end;
`;

export default ActionSectionBottom;
