import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { CompatibleImg } from '../Img';
import { useMainPageData } from '../../queries/useMainPageData';
import { device } from '../commonStyled';
import ActionSection from '../common/ActionSection/ActionSection';
import { CONTENT_WIDTH } from '../../constants';

const DESCRIPTION_WIDTH = 720;

const ActionSectionMiddle: React.FC<{
    whiteStyle?: boolean;
    noButton?: boolean;
}> = ({ whiteStyle = false, noButton = false }) => {
    const {
        action_block_middle: {
            highlightedText,
            image,
            infoText,
            buttonText,
            buttonLink,
            title,
        },
    } = useMainPageData();

    return (
        <ActionSection
            buttonText={buttonText}
            description={infoText}
            title={title}
            highlightedText={highlightedText}
            whiteStyle={whiteStyle}
            contentWidth={DESCRIPTION_WIDTH}
            imagePart={
                <CompatibleWrap whiteStyle={whiteStyle}>
                    <CompatibleContainer whiteStyle={whiteStyle}>
                        {image && <CompatibleImg image={image?.localFile} />}
                    </CompatibleContainer>
                </CompatibleWrap>
            }
            linkButton={!noButton && buttonLink}
        />
    );
};

const CompatibleWrap = styled('div')<{ whiteStyle: boolean }>`
    position: relative;
    margin-left: 30px;
    width: ${CONTENT_WIDTH - DESCRIPTION_WIDTH}px;

    @media ${device.laptopMedium} {
        display: none;
    }

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: calc(-100vw + (${CONTENT_WIDTH}px) / 2 + 100%);
        z-index: 1;
        background-color: ${({ whiteStyle }) =>
            whiteStyle ? '#fff' : '#f7f0ed'};
    }

    &:after {
        content: '';
        z-index: 1;
        position: absolute;
        bottom: 0;
        left: -100px;
        height: 100%;
        width: 100px;

        ${({ whiteStyle }) =>
            whiteStyle
                ? css`
                      background: linear-gradient(
                          90deg,
                          rgba(255, 255, 255, 0) 0%,
                          rgba(255, 255, 255, 0.76) 76.08%,
                          rgba(255, 255, 255, 0.89) 88.6%,
                          #fff 100%
                      );
                  `
                : css`
                      background: linear-gradient(
                          90deg,
                          rgba(247, 240, 237, 0) 0%,
                          rgba(247, 240, 237, 0.76) 76.08%,
                          rgba(247, 240, 237, 0.89) 88.6%,
                          #f7f0ed 100%
                      );
                  `}
    }
`;

const CompatibleContainer = styled('div')<{ whiteStyle: boolean }>`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    z-index: 1;
    align-self: flex-end;
    background-color: ${({ whiteStyle }) => (whiteStyle ? '#fff' : '#f7f0ed')};

    &:after {
        content: '';
        z-index: 1;
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        height: 20px;

        ${({ whiteStyle }) =>
            whiteStyle &&
            css`
                background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 0.76) 76.08%,
                    rgba(255, 255, 255, 0.89) 88.6%,
                    #fff 100%
                );
            `}
    }
`;

export default ActionSectionMiddle;
