import React from 'react';
import styled from '@emotion/styled';
import { ProductJsonLd } from 'gatsby-plugin-next-seo';

import { MarketModel } from '../../models';
import { FONT_GOTHAM_MEDIUM } from '../../styles/fonts';
import { CompatibleImg } from '../Img';
import { FlexSpacer, RoundedButtonWithArrow, device } from '../commonStyled';
import { COMPANY_NAME } from '../../constants';
import { getAbsoluteImageUrl } from '../../utils';

interface CoursesItemProps {
    item: MarketModel;
    className?: string;
}

const Title = styled.div`
    min-height: 48px;
    color: #000000;
    font-size: 20px;
    font-family: ${FONT_GOTHAM_MEDIUM};
    line-height: 26px;
    text-align: center;
    margin-bottom: 18px;
    font-weight: bold;
    flex-grow: 1;
`;

const Description = styled.div`
    color: #000000;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 12px;
`;

const Button = styled(RoundedButtonWithArrow)`
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 24px;
    height: 53px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
`;

const Container = styled.a`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    text-decoration: none;
    box-shadow: 9px 7px 25px 0 #d8cece;
    background-color: #ffffff;
    margin: 57px;
    min-height: 520px;
    border-radius: 22px;
    padding: 0 22px;

    &:hover {
        color: #000;
    }

    @media ${device.mobileMedium} {
        margin: 57px 24px 57px 24px;
    }
`;

const Price = styled.div`
    color: #a60424;
    font-size: 24px;
    text-align: center;
    margin-bottom: 25px;
`;

const ImageContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    margin-top: 22px;
    border-radius: 22px;
    overflow: hidden;
`;

const ImageWrapper = styled.div`
    width: 100%;
`;

const ProductItem: React.FC<CoursesItemProps> = ({
    item: { image, link, price, short_description, title, button_text },
    className,
}) => {
    return (
        <>
            <ProductJsonLd
                productName={title}
                images={getAbsoluteImageUrl(image)}
                description={short_description}
                brand={COMPANY_NAME}
                aggregateRating={{
                    ratingValue: '5',
                    reviewCount: 10,
                }}
                offers={{
                    price,
                    priceCurrency: 'RUB',
                    itemCondition: 'NewCondition',
                    availability: 'InStock',
                    url: link,
                    seller: {
                        name: COMPANY_NAME,
                    },
                }}
            />
            <>
                <Container
                    href={link}
                    rel="nofollow"
                    target="_blank"
                    className={className}
                >
                    {image?.localFile && (
                        <ImageContainer>
                            <ImageWrapper>
                                <CompatibleImg image={image?.localFile} />
                            </ImageWrapper>
                        </ImageContainer>
                    )}
                    {short_description && (
                        <Description
                            dangerouslySetInnerHTML={{
                                __html: short_description,
                            }}
                        />
                    )}
                    {title && (
                        <Title dangerouslySetInnerHTML={{ __html: title }} />
                    )}
                    <Price>{price}</Price>
                    <FlexSpacer />
                    <Button as="div">{button_text || 'Купить'}</Button>
                </Container>
            </>
        </>
    );
};

export default ProductItem;
