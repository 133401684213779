import { css } from '@emotion/react';
import styled from '@emotion/styled';
import TextareaAutosize from 'react-textarea-autosize';

import { TextInputStyles } from './Input';

export const Textarea = styled(TextareaAutosize)<{ isError?: boolean }>`
    ${TextInputStyles}
    margin-bottom: 22px;
    resize: none;
    ${(props) =>
        props.isError &&
        css`
            border: 1px solid #a60424;
        `};
`;
