import React from 'react';

import { useSuccessStories } from '../../queries/useSuccessStories';
import SectionWithSliderSticked from '../common/SectionWithSliderSticked/SectionWithSliderSticked';
import { SuccessStoryItemModel } from '../../models';

import SuccessStoryItem from './SuccessStoryItem';

const SuccessStories: React.FC<{ title: string }> = ({ title }) => {
    const items = useSuccessStories();

    return (
        <SectionWithSliderSticked<SuccessStoryItemModel>
            items={items}
            title={title}
            sliderElement={SuccessStoryItem}
            sliderId="success_stories"
        />
    );
};

export default SuccessStories;
