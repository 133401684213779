import React from 'react';
import styled from '@emotion/styled';

import { CalendarEventsModel } from '../../models';
import { FONT_GOTHAM_LIGHT, FONT_GOTHAM_MEDIUM } from '../../styles/fonts';
import { device } from '../commonStyled';

interface CoursesItemProps {
    item: CalendarEventsModel;
}

const CalendarEventsSliderItem: React.FC<CoursesItemProps> = ({
    item: { event_type, start_date, title, link },
}) => {
    return (
        <Container
            itemScope
            itemType="https://schema.org/Event"
            target="_blank"
            rel="nofollow"
            href={link}
        >
            <EventDate itemProp="startDate">{start_date}</EventDate>
            <EventType dangerouslySetInnerHTML={{ __html: event_type }} />
            <EventTitle
                itemProp="name"
                dangerouslySetInnerHTML={{ __html: title }}
            />
        </Container>
    );
};

const EventDate = styled.div`
    color: #a60424;
    font-family: ${FONT_GOTHAM_LIGHT};
    font-size: 36px;
    line-height: 24px;
    margin-bottom: 14px;
    text-align: center;
`;

const EventType = styled.div`
    color: #000000;
    font-size: 24px;
    font-family: ${FONT_GOTHAM_MEDIUM};
    text-align: center;
    margin-bottom: 4px;
`;
const EventTitle = styled.div`
    color: #000000;
    font-size: 24px;
    font-family: ${FONT_GOTHAM_LIGHT};
    text-align: center;
`;

const Container = styled.a`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    text-decoration: none;
    box-shadow: 9px 7px 55px 0 #d8cece;
    background-color: #ffffff;
    margin: 57px 57px 73px 73px;
    height: 250px;
    min-height: 250px;
    border-radius: 22px;

    @media ${device.mobileMedium} {
        margin: 57px 24px 73px 24px;
        height: auto;
    }

    padding: 8px;

    &:hover {
        color: #000;
    }
`;

export default CalendarEventsSliderItem;
