import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

interface CheckboxProps {
    isChecked: boolean;
    isError?: boolean;
    onChange(e: any): void;
    className?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
    isChecked,
    onChange,
    className,
    isError,
}) => {
    return (
        <Label isChecked={isChecked} isError={isError} className={className}>
            <CheckboxInput
                type="checkbox"
                checked={isChecked}
                onChange={onChange}
            />
        </Label>
    );
};

const Label = styled('label')<{ isChecked: boolean; isError: boolean }>`
    position: relative;
    height: 17px;
    width: 17px;
    border: 1px solid #a2a2a2;
    border-radius: 5px;
    flex-shrink: 0;

    &:after {
        content: '';
        position: absolute;
        left: 2.1px;
        top: 6.5px;
        background: #a60424;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 #a60424, 4px 0 0 #a60424, 4px -2px 0 #a60424,
            4px -4px 0 #a60424, 4px -6px 0 #a60424, 4px -8px 0 #a60424;
        transform: rotate(45deg);
        opacity: 0;
        transition: all 0.2s ease-in-out;
    }

    ${({ isError }) =>
        isError &&
        css`
            border-color: #a60424;
        `}

    ${({ isChecked }) =>
        isChecked &&
        css`
            &:after {
                opacity: 1;
            }
        `}
`;

const CheckboxInput = styled.input`
    position: absolute;
    opacity: 0;

    ${Label} > &:focus {
        ${Label} {
            border-color: #a60424;
        }
    }
`;

export default Checkbox;
