import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { PostStickyModel } from '../../models';
import { CompatibleImg } from '../Img';
import { FONT_GOTHAM_LIGHT } from '../../styles/fonts';
import { FlexSpacer, device } from '../commonStyled';
import { ROUTES } from '../../constants';

interface ArticleProps {
    item: PostStickyModel;
}

const Article: React.FC<ArticleProps> = ({
    item: { featured_media: featured_media_raw, slug, title },
}) => {
    const { node: featured_media } = featured_media_raw || {};

    return (
        <Container to={`/${ROUTES.articles}/${slug}`} rel="nofollow">
            {featured_media?.localFile && (
                <CompatibleImg
                    image={featured_media.localFile}
                    imageStyle={imageStyle}
                />
            )}
            <FlexSpacer />
            <Title dangerouslySetInnerHTML={{ __html: title }} />
        </Container>
    );
};

const Title = styled.div`
    position: relative;
    z-index: 1;
    color: #ffffff;
    font-family: ${FONT_GOTHAM_LIGHT};
    line-height: 28px;
    font-size: 18px;
`;

const imageStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
};

const Container = styled(Link)`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 15px;
    padding: 60px;

    height: 300px;
    width: 100%;

    & + & {
        margin-left: 50px;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background-color: #000000;
        opacity: 0.3;
    }

    @media ${device.laptopSmall} {
        padding: 24px;
        &:not(:last-child) {
            margin-bottom: 24px;
        }

        & + & {
            margin-left: 12px;
        }
    }

    @media ${device.tabletMedium} {
        & + & {
            margin-left: 0;
        }
    }
`;

export default Article;
