import React from 'react';
import styled from '@emotion/styled';

import SectionTitle from '../SectionTitle';
import SectionDevider from '../SectionDevider';
import { useServices } from '../../queries/useServices';

import ServiceCard from './ServiceCard';

interface ServiceSectionProps {
    title: string;
}

const ServiceSection: React.FC<ServiceSectionProps> = ({ title }) => {
    const services = useServices();

    return (
        <>
            <SectionDevider />
            <Container>
                <SectionTitle title={title} />
                <ServicesContainer>
                    {services.map((item, i) => (
                        <ServiceCard
                            key={i}
                            title={item.category}
                            slides={item.items}
                            sliderId={`services_${i}`}
                        />
                    ))}
                </ServicesContainer>
            </Container>
        </>
    );
};

const ServicesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    width: 100%;
`;

export default ServiceSection;
