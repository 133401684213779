import { useStaticQuery, graphql } from 'gatsby';
import { parse } from 'date-fns';

import { CourseModel } from '../models';
import { formatWpDate, parseWpDate } from '../utils';

export const useCourses = (): CourseModel[] => {
    const { allWpCourse } = useStaticQuery(
        graphql`
            {
                allWpCourse(
                    sort: { fields: courses_acf___startDate, order: ASC }
                ) {
                    nodes {
                        courses_acf {
                            description
                            target
                            image {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(width: 500)
                                    }
                                }
                                altText
                            }
                            start_date: startDate
                            start_date_raw: startDate
                            subtitle
                            title
                            url
                        }
                    }
                }
            }
        `
    );

    const nodes = allWpCourse?.nodes;
    const courses =
        nodes?.map((item) => ({
            ...item?.courses_acf,
            start_date: formatWpDate(
                item?.courses_acf?.start_date,
                'dd MMMM yyyy'
            ),
            start_date_raw: parseWpDate(item?.courses_acf?.start_date_raw),
        })) || [];

    // const pastCourses = courses.filter((x) => isPast(x.start_date_raw));
    // const newCourses = courses.filter((x) => !isPast(x.start_date_raw));
    // return [...newCourses, ...pastCourses];

    return courses;
};
