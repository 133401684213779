import { useStaticQuery, graphql } from 'gatsby';

import { SuccessStoryItemModel } from '../models';

export const useSuccessStories = (): SuccessStoryItemModel[] => {
    const { allWpSuccessStory } = useStaticQuery(
        graphql`
            {
                allWpSuccessStory {
                    nodes {
                        slug
                        success_stories_acf {
                            name
                            photo {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(width: 600)
                                    }
                                }
                                altText
                            }
                            jobtitle
                            short_feedback: shortFeedback
                            contacts
                            feedback
                            success_full_photo: successFullPhoto {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(width: 600)
                                    }
                                }
                                altText
                            }
                        }
                    }
                }
            }
        `
    );

    const nodes = allWpSuccessStory?.nodes;

    return (
        nodes?.map((item) => ({
            ...item?.success_stories_acf,
            slug: item.slug,
        })) || []
    );
};
